// Angular imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal enums
import { EmployeeDefaultPage } from '../enums/admin/active-admin-page.enum';
import { EmployeeRole } from '../enums/account/employee-role.enum';
import { ActivePage } from '../enums/nav/active-page.enum';

//Internal services
import { AccountService } from '../services/account/account.service';

//Internal models
import { EmployeePermissionsObject, CorporationFeaturesObject, SelectableCorporationsBody, CorporationEmployeeListObject } from '../models/account.model';
import { SelectableChildCorporationResponse } from '../models/auth/responses/selectable-child-corporation.response';

export interface NewEmployeeInfoBody {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: EmployeeRole;
}

@Injectable()
export class AccountProvider {
    //authenticated employee permission variables
    employeePermissionsObservable = new Subject();
    employeePermissions: EmployeePermissionsObject;

    //selectable venue variables
    selectedCorporationNameObservable = new Subject<string>();
    selectedCorporationName: string;
    selectedCorporationUserId: string;

    selectableCorporations: SelectableCorporationsBody[];

    //selected venue features
    selectedVenueFeaturesObservable = new Subject();
    selectedVenueFeatures: CorporationFeaturesObject;

    //add new employee modal
    addEmployeeModal: any;
    addEmployeeModalIsOpenObservable = new Subject<boolean>();
    addEmployeeModalIsOpen: boolean;
    addEmployeeModalStepObservable = new Subject<number>();
    addEmployeeModalStep: number;
    isEditEmployeeObservable = new Subject<boolean>();
    isEditEmployee: boolean;
    employeeUserId: string;
    employeeUserIdObservable = new Subject<boolean>();

    //new employee info variables
    newEmployeeFirstName: string;
    newEmployeeLastName: string;
    newEmployeeEmail: string;
    // newEmployeePassword: string;
    newEmployeeRole: number;

    //new employee permission variables
    isSuperAdmin: boolean;
    adminDashboard_Read: boolean;
    adminDashboard_Write: boolean;
    messaging_Read: boolean;
    messaging_Write: boolean;
    crm_Read: boolean;
    crm_Write: boolean;
    settings_Read: boolean;
    settings_Write: boolean;
    organization_Read: boolean;
    organization_Write: boolean;
    employees_Read: boolean;
    employees_Write: boolean;
    corporationVenues_Read: boolean;
    corporationVenues_Write: boolean;
    accounting_Read: boolean;
    accounting_Write: boolean;


    constructor(
        public modalCtrl: ModalController,
        private appProvider: GlobalAppProvider,
        private accountService: AccountService
    ) {

    }

    //eslint-disable-next-line
    /**
     * @param  {SelectableCorporationsBody} selectableVenuesResponse
     * @summary set the currently selected corporation name as well as the selectable child corporation variables
     */
    async setSelectableCorporationDetails(selectableChildCorporationResponse: SelectableChildCorporationResponse) {
        this.selectableCorporations = selectableChildCorporationResponse.selectableCorporations;
        this.selectedCorporationUserId = selectableChildCorporationResponse.defaultCorporationUserId;
        this.selectedCorporationName = selectableChildCorporationResponse.defaultCorporationName;
        this.selectedCorporationNameObservable.next(selectableChildCorporationResponse.defaultCorporationName);
    }

    async setSelectedCorporationName(corporationName: string, corporationUserId: string) {
        this.selectedCorporationName = corporationName;
        this.selectedCorporationUserId = corporationUserId;

        this.selectedCorporationNameObservable.next(corporationName);
    }

    async getSelectedCorporationName() {
        return this.selectedCorporationName;
    }

    async setSelectableCorporations(corporations: SelectableCorporationsBody[]) {
        this.selectableCorporations = corporations;
    }

    async getSelectableCorporations() {
        return this.selectableCorporations;
    }

    // eslint-disable-next-line
    /**
     * @param  {VenueEmployeePermissionsObject} permissions
     * @task1 set the {@var employeePermissions} and {@var employeePermissionsObservable} to the passed permissions
     * @task2 get the default page value by calling {@link getDefaultPageValue} and then set the active page
     */
    async setEmployeePermissions(permissions: EmployeePermissionsObject, performNavigation: boolean) {
        //task 1
        this.employeePermissionsObservable.next(permissions);
        this.employeePermissions = permissions;

        //task 2
        const defaultPage = await this.getDefaultPageValue(this.employeePermissions.defaultPage);

        if (performNavigation) {
            this.appProvider.setActivePage(defaultPage);
        }

        //task 3 set the modal step to 1
    }

    /**
     * @returns employeePermissions {@link VenueEmployeePermissionsObject}
     */
    async getEmployeePermissions() {
        return this.employeePermissions;
    }

    async setEmployeeNewDefaultPage(defaultPage: number) {
        this.employeePermissions.defaultPage = defaultPage;
        this.employeePermissionsObservable.next(this.employeePermissions);
    }

    // eslint-disable-next-line
    /**
     * @param  {VenueFeaturesObject[]} features
     * @task1 set the {@var selectedVenueFeatures} and {@var selectedVenueFeaturesObservable} to the passed features
     */
    async setSelectedVenueFeatures(features: CorporationFeaturesObject) {
        this.selectedVenueFeaturesObservable.next(features);
        this.selectedVenueFeatures = features;
    }

    async getSelectedVenueFeatures() {
        return this.selectedVenueFeatures;
    }

    /**
     * @returns the associated active page based on {@link employeePermissions.defaultPage}
     */
    async getDefaultPageValue(defaultPage: EmployeeDefaultPage) {
        switch (defaultPage) {
            case 0: {
                return ActivePage.dashboard;
            }
            // case 3: {
            //     return 'messaging';
            // }
            // case 4: {
            //     return 'crm';
            // }
            // case 8: {
            //     return 'accounting';
            // }
            default: {
                return null;
            }
        }
    }


    /**
     * @summary this configures and presents the add employee modal
     * @task1 present the add employee modal
     * @task2 set the addEmployeeModalIsOpen variable to true and update it's observable respectively
     * @task3 subscribe to the addEmployeeModal onDidDismiss and then reset all variables upon dismiss
     * @task4 set the addEmployeeModalStep to 1 by calling {@link setAddEmployeeModalStep}
     */
     async presentAddEmployeeModal(isEditEmployee: boolean){
        
    }

    async dismissAddEmployeeModal(){
        await this.addEmployeeModal.dismiss();

        this.addEmployeeModalIsOpen = false;
        this.addEmployeeModalIsOpenObservable.next(false);
    }

    async getIsEditEmployee() {
        return this.isEditEmployee;
    }

    resetAllAddEmployeeVariables() {
        this.newEmployeeFirstName = null;
        this.newEmployeeLastName = null;
        this.newEmployeeEmail = null;
        // this.newEmployeePassword = null;
        this.newEmployeeRole = null;
        this.isSuperAdmin = undefined;
        this.adminDashboard_Read = undefined;
        this.adminDashboard_Write = undefined;
        this.messaging_Read = undefined;
        this.messaging_Write = undefined;
        this.crm_Read = undefined;
        this.crm_Write = undefined;
        this.settings_Read = undefined;
        this.settings_Write = undefined;
        this.organization_Read = undefined;
        this.organization_Write = undefined;
        this.employees_Read = undefined;
        this.employees_Write = undefined;
        this.corporationVenues_Read = undefined;
        this.corporationVenues_Write = undefined;
        this.accounting_Read = undefined;
        this.accounting_Write = undefined;
        this.isEditEmployee = false;
    }

    // eslint-disable-next-line
    /**
     * @param  {number} modalStep
     * @task1 set the {@var addEmployeeModalStep} to the passed modalStep
     * @task2 call the {@method next()} on {@var addEmployeeModalStepObservable} so that...
     * all subscriptions in other components fetch the new {@var addEmployeeModalStep}
     */
     setAddEmployeeModalStep(modalStep: number) {
        //task 1
        this.addEmployeeModalStep = modalStep;
        //task 2
        this.addEmployeeModalStepObservable.next(modalStep);
    }


    /**
     * @returns addEmployeeModalStep {number}
     */
    getAddEmployeeModalStep() {
        return this.addEmployeeModalStep;
    }

    //eslint-disable-next-line
    /**
     * @param  {NewEmployeeInfoBody} employeeInfo
     * @summary set all of the new employee information variables to the passed items respectively
     */
    async setNewEmployeeInfoVariables(employeeInfo: NewEmployeeInfoBody) {
        this.newEmployeeFirstName = employeeInfo.firstName;
        this.newEmployeeLastName = employeeInfo.lastName;
        this.newEmployeeEmail = employeeInfo.email;
        // this.newEmployeePassword = employeeInfo.password;
        this.newEmployeeRole = employeeInfo.role;
    }


    async getEmployeeInfoVariables() {
        const info: NewEmployeeInfoBody = {
            firstName: this.newEmployeeFirstName,
            lastName: this.newEmployeeLastName,
            email: this.newEmployeeEmail,
            password: null,
            role: this.newEmployeeRole
        };

        return info;
    }

    async getEmployeePermissionVariables() {
        const info: EmployeePermissionsObject = {
            isSuperAdmin: this.isSuperAdmin,
            adminDashboard_Read: this.adminDashboard_Read,
            adminDashboard_Write: this.adminDashboard_Write,
            messaging_Read: this.messaging_Read,
            messaging_Write: this.messaging_Write,
            crm_Read: this.crm_Read,
            crm_Write: this.crm_Write,
            settings_Read: this.settings_Read,
            settings_Write: this.settings_Write,
            organization_Read: this.organization_Read,
            organization_Write: this.organization_Write,
            employees_Read: this.employees_Read,
            employees_Write: this.employees_Write,
            corporationVenues_Read: this.corporationVenues_Read,
            corporationVenues_Write: this.corporationVenues_Write,
            accounting_Read: this.accounting_Read,
            accounting_Write: this.accounting_Write,
            corporationEmployeePermissionId: null,
            corporationUserId: null,
            employeeUserId: null,
            defaultPage: null,
            createDate: null,
            lastChanged: null
        };

        return info;
    }

    //eslint-disable-next-line
    /**
     * @param  {EmployeePermissionsObject} employeePermissions
     * @summary set all of the new employee permission variables to the passed items respectively
     */
    async setNewEmployeePermissionVariables(employeePermissions: EmployeePermissionsObject) {
        this.isSuperAdmin = employeePermissions.isSuperAdmin;
        this.adminDashboard_Read = employeePermissions.adminDashboard_Read;
        this.adminDashboard_Write = employeePermissions.adminDashboard_Write;
        this.messaging_Read = employeePermissions.messaging_Read;
        this.messaging_Write = employeePermissions.messaging_Write;
        this.crm_Read = employeePermissions.crm_Read;
        this.crm_Write = employeePermissions.crm_Write;
        this.settings_Read = employeePermissions.settings_Read;
        this.settings_Write = employeePermissions.settings_Write;
        this.organization_Read = employeePermissions.organization_Read;
        this.organization_Write = employeePermissions.organization_Write;
        this.employees_Read = employeePermissions.employees_Read;
        this.employees_Write = employeePermissions.employees_Write;
        this.corporationVenues_Read = employeePermissions.corporationVenues_Read;
        this.corporationVenues_Write = employeePermissions.corporationVenues_Write;
        this.accounting_Read = employeePermissions.accounting_Read;
        this.accounting_Write = employeePermissions.accounting_Write;
    }

    /**
     * @returns the new employee request body which is composed of the new employee info and permissions
     */
    async getNewEmployeeRequestBody() {
        const body = {
            isParentCorporationEmployee: await this.accountService.getIsParentCorporationEmployee(),
            corporationUserId: await this.accountService.getCorporationUserId(),
            newEmployeeFirstName: this.newEmployeeFirstName != null ? this.newEmployeeFirstName : null,
            newEmployeeLastName: this.newEmployeeLastName != null ? this.newEmployeeLastName : null,
            newEmployeeEmail: this.newEmployeeEmail != null ? this.newEmployeeEmail : null,
            // newEmployeePassword: this.newEmployeePassword != null ? this.newEmployeePassword : null,
            newEmployeeRole: this.newEmployeeRole != null ? this.newEmployeeRole : null,
            isSuperAdmin: this.isSuperAdmin != null ? this.isSuperAdmin : false,
            adminDashboard_Read: this.adminDashboard_Read != null ? this.adminDashboard_Read : false,
            adminDashboard_Write: this.adminDashboard_Write != null ? this.adminDashboard_Write : false,
            messaging_Read: this.messaging_Read != null ? this.messaging_Read : false,
            messaging_Write: this.messaging_Write != null ? this.messaging_Write : false,
            crm_Read: this.crm_Read != null ? this.crm_Read : false,
            crm_Write: this.crm_Write != null ? this.crm_Write : false,
            settings_Read: this.settings_Read != null ? this.settings_Read : false,
            settings_Write: this.settings_Write != null ? this.settings_Write : false,
            organization_Read: this.organization_Read != null ? this.organization_Read : false,
            organization_Write: this.organization_Write != null ? this.organization_Write : false,
            employees_Read: this.employees_Read != null ? this.employees_Read : false,
            employees_Write: this.employees_Write != null ? this.employees_Write : false,
            corporationVenues_Read: this.corporationVenues_Read != null ? this.corporationVenues_Read : false,
            corporationVenues_Write: this.corporationVenues_Write != null ? this.corporationVenues_Write : false,
            accounting_Read: this.accounting_Read != null ? this.accounting_Read : false,
            accounting_Write: this.accounting_Write != null ? this.accounting_Write : false
        };

        return body;
    }

    async getEditEmployeeRequestBody() {
        const body = {
            employeeUserId: this.employeeUserId,
            isParentCorporationEmployee: await this.accountService.getIsParentCorporationEmployee(),
            corporationUserId: await this.accountService.getCorporationUserId(),
            newEmployeeFirstName: this.newEmployeeFirstName != null ? this.newEmployeeFirstName : null,
            newEmployeeLastName: this.newEmployeeLastName != null ? this.newEmployeeLastName : null,
            newEmployeeEmail: this.newEmployeeEmail != null ? this.newEmployeeEmail : null,
            newEmployeePassword: null,
            newEmployeeRole: this.newEmployeeRole != null ? this.newEmployeeRole : null,
            isSuperAdmin: this.isSuperAdmin != null ? this.isSuperAdmin : false,
            adminDashboard_Read: this.adminDashboard_Read != null ? this.adminDashboard_Read : false,
            adminDashboard_Write: this.adminDashboard_Write != null ? this.adminDashboard_Write : false,
            messaging_Read: this.messaging_Read != null ? this.messaging_Read : false,
            messaging_Write: this.messaging_Write != null ? this.messaging_Write : false,
            crm_Read: this.crm_Read != null ? this.crm_Read : false,
            crm_Write: this.crm_Write != null ? this.crm_Write : false,
            settings_Read: this.settings_Read != null ? this.settings_Read : false,
            settings_Write: this.settings_Write != null ? this.settings_Write : false,
            organization_Read: this.organization_Read != null ? this.organization_Read : false,
            organization_Write: this.organization_Write != null ? this.organization_Write : false,
            employees_Read: this.employees_Read != null ? this.employees_Read : false,
            employees_Write: this.employees_Write != null ? this.employees_Write : false,
            corporationVenues_Read: this.corporationVenues_Read != null ? this.corporationVenues_Read : false,
            corporationVenues_Write: this.corporationVenues_Write != null ? this.corporationVenues_Write : false,
            accounting_Read: this.accounting_Read != null ? this.accounting_Read : false,
            accounting_Write: this.accounting_Write != null ? this.accounting_Write : false,
        };

        return body;
    }


    async setEditableEmployee(employee: CorporationEmployeeListObject) {
        this.newEmployeeFirstName = employee.employeeFirstName;
        this.newEmployeeLastName = employee.employeeLastName;
        this.newEmployeeEmail = employee.employeeEmail;
        this.employeeUserId = employee.employeeUserId;
        this.newEmployeeRole = employee.employeeRole;

        this.isSuperAdmin = employee.permissions.isSuperAdmin;
        this.adminDashboard_Read = employee.permissions.adminDashboard_Read;
        this.adminDashboard_Write = employee.permissions.adminDashboard_Write;
        this.messaging_Read = employee.permissions.messaging_Read;
        this.messaging_Write = employee.permissions.messaging_Write;
        this.crm_Read = employee.permissions.crm_Read;
        this.crm_Write = employee.permissions.crm_Write;
        this.settings_Read = employee.permissions.settings_Read;
        this.settings_Write = employee.permissions.settings_Write;
        this.organization_Read = employee.permissions.organization_Read;
        this.organization_Write = employee.permissions.organization_Write;
        this.employees_Read = employee.permissions.employees_Read;
        this.employees_Write = employee.permissions.employees_Write;
        this.corporationVenues_Read = employee.permissions.corporationVenues_Read;
        this.corporationVenues_Write = employee.permissions.corporationVenues_Write;
        this.accounting_Read = employee.permissions.accounting_Read;
        this.accounting_Write = employee.permissions.accounting_Write;
    }

}
