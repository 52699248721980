/* eslint-disable max-len */
//Angular variables
import { Injectable } from '@angular/core';

// Internal services
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseHttpService {

  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async addParentCorporation(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/AddNewParentCorporation`;
    return this.post<any>(endpoint, body, retry);
  }

  async searchParentVenues(searchString: string, page: number, pageSize: number, retry = true): Promise<any | null> {
    if (searchString === null || searchString === '') {
      return [];
    } else {
      const requestBody = {
        searchString,
        page,
        pageSize
      };

      const endpoint = `/api/Admin/SearchParentVenues`;
      return this.post<any>(endpoint, requestBody, retry);
    }
  }

  async upsertVenueTrackmanCredentials(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/VenueCredentials/UpsertVenueTrackmanCredentials`;
    return this.post<any>(endpoint, body, retry);
  }

  async upsertVenueOpenTableCredentials(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/VenueCredentials/UpsertVenueOpenTableCredentials`;
    return this.post<any>(endpoint, body, retry);
  }

  async upsertVenueTripleseatCredentials(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/VenueCredentials/UpsertVenueTripleseatCredentials`;
    return this.post<any>(endpoint, body, retry);
  }

  async addChildCorporation(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/AddNewChildCorporation`;
    return this.post<any>(endpoint, body, retry);
  }

  async addCorporationEmployee(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/AddCorporationEmployee`;
    return this.post<any>(endpoint, body, retry);
  }

  async upsertVenueBayMapping(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/UpsertVenueBayMapping`;
    return this.post<any>(endpoint, body, retry);
  }

  async addVenueEmployee(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/AddVenueEmployee`;
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  async searchAllCorporations(searchString: string, retry = true): Promise<any | null> {
    if (searchString === null || searchString === '') {
      return [];
    } else {
      const endpoint = `/api/Admin/SearchAllCorps?searchString=${searchString}`;
      return this.get<any>(endpoint, retry);
    }
  }

  async checkAdminCredentials(password: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/CheckAdminCredentials?password=${password}`;
    return this.get<any>(endpoint, retry);
  }

  async getTrackmanLocationId(cuid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/VenueCredentials/TrackmanLocationId?cuid=${cuid}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueDefaultFloors(corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/VenueMappableDefaultFloors?cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueDefaultFloorBays(dfid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/VenueMappableDefaultFloorBays?dfid=${dfid}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueEmployees(corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/GetVenueEmployees?cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async updateVenueEmployee(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/UpdateVenueEmployee`;
    return this.put<any>(endpoint, body, retry);
  }

  // ! DELETE Requests
  async removeVenueEmployee(venueEmployeeId: string, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Admin/RemoveVenueEmployee?venueEmployeeId=${venueEmployeeId}`;
    return this.delete<any>(endpoint, body, retry);
  }
}
