import { Injectable } from '@angular/core';

// Internal interfaces
import { AuditLogQueryParameters } from 'src/app/models/audit-logs/audit-log-query-parameters.model';

// Internal models
import { PaginatedList } from 'src/app/models/paginted-list.model';
import { AuditLogObject } from 'src/app/models/audit-logs/audit-log.model';

// Internal services
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  

  // ! GET Requests
  async toQueryStringParameters?(parameters: AuditLogQueryParameters): Promise<string> {
    let requestUri = '';

    if (parameters.entityName) {
      requestUri += requestUri == '' ? `?entityName=${parameters.entityName}` : `&entityName=${parameters.entityName}`;
    }

    if (parameters.entityId) {
      requestUri += requestUri == '' ? `?entityId=${parameters.entityId}` : `&entityId=${parameters.entityId}`;
    }

    if (parameters.action) {
      requestUri += requestUri == '' ? `?action=${parameters.action}` : `&action=${parameters.action}`;
    }

    if (parameters.changedBy) {
      requestUri += requestUri == '' ? `?changedBy=${parameters.changedBy}` : `&changedBy=${parameters.changedBy}`;
    }

    if (parameters.changedAtFrom) {
      requestUri += requestUri == '' ? `?changedAtFrom=${parameters.changedAtFrom}` : `&changedAtFrom=${parameters.changedAtFrom}`;
    }

    if (parameters.changedAtTo) {
      requestUri += requestUri == '' ? `?changedAtTo=${parameters.changedAtTo}` : `&changedAtTo=${parameters.changedAtTo}`;
    }

    if (parameters.searchTerm) {
      requestUri += requestUri == '' ? `?searchTerm=${parameters.searchTerm}` : `&searchTerm=${parameters.searchTerm}`;
    }

    return requestUri;
  }

  async getAuditLogs(parameters: AuditLogQueryParameters, retry = true): Promise<any | null> {
    let endpoint = `/api/AuditLogs/GetAuditLogs`;
    const queryParameters = await this.toQueryStringParameters(parameters);
    console.log(queryParameters);
    endpoint += queryParameters;
    console.log(endpoint);
    return this.get<PaginatedList<AuditLogObject>>(endpoint, retry);
  }

  // ! PUT Requests
  
}
