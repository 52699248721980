import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  

  // ! GET Requests
  async getMarkedAsPaidByUserName(markedAsPaidByUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Payments/MarkedAsPaidByUserName?uid=${encodeURIComponent(markedAsPaidByUserId)}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async updateReservationTotal(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Payments/ReservationTotal`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateReservationPaidUpfront(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Payments/ReservationPaidUpfront`;
    return this.put<any>(endpoint, body, retry);
  }

  async markReservationAsPaid(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Payments/MarkReservationAsPaid`;
    return this.put<any>(endpoint, body, retry);
  }

  async cancelReservationDueUpfront(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Payments/CancelReservationDueUpfront`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateReservationFinalSpend(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Payments/ReservationFinalSpend`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateReservationComped(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Payments/ReservationComped`;
    return this.put<any>(endpoint, body, retry);
  }
}
