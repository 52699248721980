//Angular variables
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Capacitor variables
import { CapacitorHttp, HttpResponse, HttpOptions } from '@capacitor/core';

//Internal service
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

//Internal variables
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseHttpService {

  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async getBayUtilizationByDayOfWeek(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetBayUtilizationByDayOfWeek';
    return this.post<any>(endpoint, body, retry);
  }

  async getDailyBayReservationHeatmapByFloor(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetDailyBayReservationHeatmapByFloor';
    return this.post<any>(endpoint, body, retry);
  }

  async getCancellationRate(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetCancellationRate';
    return this.post<any>(endpoint, body, retry);
  }

  async getCustomerAgeDistribution(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetCustomerAgeDistribution';
    return this.post<any>(endpoint, body, retry);
  }

  async getCustomerRetentionRate(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetCustomerRetentionRate';
    return this.post<any>(endpoint, body, retry);
  }

  async getNoShowRate(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetNoShowRate';
    return this.post<any>(endpoint, body, retry);
  }

  async getReservationDurationDistribution(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetReservationDurationDistribution';
    return this.post<any>(endpoint, body, retry);
  }

  async getReservationLeadTime(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetReservationLeadTime';
    return this.post<any>(endpoint, body, retry);
  }

  async getTopCustomersBySpend(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetTopCustomersBySpend';
    return this.post<any>(endpoint, body, retry);
  }

  async getTotalReservationsCount(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetTotalReservationsCount';
    return this.post<any>(endpoint, body, retry);
  }

  async getOccupancyRate(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetOccupancyRate';
    return this.post<any>(endpoint, body, retry);
  }

  async getRevenuePerReservation(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetRevenuePerReservation';
    return this.post<any>(endpoint, body, retry);
  }

  async getPeakHourAnalysis(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetPeakHourAnalysis';
    return this.post<any>(endpoint, body, retry);
  }

  async getCustomerVisitFrequency(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Dashboard/GetCustomerVisitFrequency';
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  async getCorporationActiveDefaultFloors(corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Dashboard/GetCorporationActiveDefaultFloors?cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  async getCustomerProfileVenueId(guestProfileId: string, corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Dashboard/GetCustomerProfileVenueId?gpid=${guestProfileId}&cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  
}
