import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class GuestService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async addGuestSocialAccount(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/SocialAccount`;
    return this.post<any>(endpoint, body, retry);
  }

  async addGuestTag(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/Tag`;
    return this.post<any>(endpoint, body, retry);
  }

  async addNewGuestNote(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/AddNote`;
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  async getSearchedGuestsByEmail(email: string, corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/SearchedGuestsByEmail?email=${encodeURIComponent(email)}&cuid=${encodeURIComponent(corporationUserId)}`;
    return this.get<any>(endpoint, retry);
  }

  async getSearchedGuestsByPhoneNumber(phoneNumber: string, corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/SearchedGuestsByPhoneNumber?phoneNumber=${encodeURIComponent(phoneNumber)}&cuid=${encodeURIComponent(corporationUserId)}`;
    return this.get<any>(endpoint, retry);
  }

  async getGuestCompany(guestProfileId: string, cuid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/Company?gpid=${encodeURIComponent(guestProfileId)}&cuid=${encodeURIComponent(cuid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getGuestSocialAccounts(guestProfileId: string, cuid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/SocialAccounts?gpid=${encodeURIComponent(guestProfileId)}&cuid=${encodeURIComponent(cuid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getGuestGender(guestProfileId: string, cuid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/Gender?gpid=${encodeURIComponent(guestProfileId)}&cuid=${encodeURIComponent(cuid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getGuestTags(guestProfileId: string, cuid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/Tags?gpid=${encodeURIComponent(guestProfileId)}&cuid=${encodeURIComponent(cuid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getGuestProfileVenueId(guestProfileId: string, cuid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/ProfileVenueId?gpid=${encodeURIComponent(guestProfileId)}&cuid=${encodeURIComponent(cuid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getGuestNotes(getReservationNote: boolean, reservationId: string, guestProfileVenueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/Notes?res=${encodeURIComponent(getReservationNote)}&rid=${encodeURIComponent(reservationId)}&gpvid=${encodeURIComponent(guestProfileVenueId)}`;
    return this.get<any>(endpoint, retry);
  }

  async searchGuestNotes(getReservationNote: boolean, reservationId: string, guestProfileVenueId: string, searchString: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/SearchNotes?res=${encodeURIComponent(getReservationNote)}&rid=${encodeURIComponent(reservationId)}&gpvid=${encodeURIComponent(guestProfileVenueId)}&q=${encodeURIComponent(searchString)}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async updateGuestCompany(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/Company`;
    return this.put<any>(endpoint, body, retry);
  }

  async deactivateGuestTag(tagId: string, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/DeactivateTag?gtid=${encodeURIComponent(tagId)}`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateGuestNote(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/UpdateNote`;
    return this.put<any>(endpoint, body, retry);
  }

  // ! DELETE Requests
  async removeGuestNote(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Guest/RemoveNote`;
    return this.delete<any>(endpoint, body, retry);
  }
}
