// Angular imports
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal services
import { AccountService } from '../services/account/account.service';
import { VenueService } from '../services/venue/venue.service';

//Internal models
import { CurrentVenueISOTimeResponse } from '../models/venue.model';

@Injectable()
export class VenueProvider {

    constructor(
        private accountService: AccountService,
        private globalAppProvider: GlobalAppProvider,
        private venueService: VenueService
    ) { }

    async getVenueCurrentISOTime(): Promise<string> {
        const corporationUserId = await this.accountService.getCorporationUserId();
        const ianaTimeZone = await this.venueService.getVenueCurrentISOTime(corporationUserId)
          .then((data: CurrentVenueISOTimeResponse) => {
            const windowsToIanaMap = {
                "Eastern Standard Time": "America/New_York",
                "Eastern Daylight Time": "America/New_York",
                "Central Standard Time": "America/Chicago",
                "Central Daylight Time": "America/Chicago",
                "Mountain Standard Time": "America/Denver",
                "Mountain Daylight Time": "America/Denver",
                "Pacific Standard Time": "America/Los_Angeles",
                "Pacific Daylight Time": "America/Los_Angeles",
                "Alaskan Standard Time": "America/Anchorage",
                "Alaskan Daylight Time": "America/Anchorage",
                "Hawaiian Standard Time": "Pacific/Honolulu",
                "Hawaiian Daylight Time": "Pacific/Honolulu",
            };
            return windowsToIanaMap[data.timeZone];
          })
          .catch((error) => {
            console.log(error);
            return null;
          });
        return ianaTimeZone;
    }
}