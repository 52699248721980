import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class MembershipService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async upsertCorporationMembership(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Memberships/UpsertCorporationMembership`;
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  async getCorporationMemberships(cid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Memberships/CorporationMemberships?cid=${cid}`;
    return this.get<any>(endpoint, retry);
  }
  

  // ! PUT Requests
  
}
