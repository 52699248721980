//Angular variables
import { Injectable } from '@angular/core';

// Internal services
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class LightingControllerService extends BaseHttpService {

  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async upsertCorporationLightingControllerEndpoint(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Lighting/UpsertCorporationLightingControllerEndpoint`;
    return this.post<any>(endpoint, body, retry);
  }

  async upsertLightingBayStatuses(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Lighting/UpsertLightingBayStatuses`;
    return this.post<any>(endpoint, body, retry);
  }

  async upsertLightingBayMap(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Lighting/UpsertLightingBayMap`;
    return this.post<any>(endpoint, body, retry);
  }
  
  // ! GET Requests
  async getAllVenueDefaultBays(corporationDetailId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Lighting/AllVenueDefaultBays?cid=${corporationDetailId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueLightingControllerEndpoint(corporationDetailId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Lighting/VenueLightingControllerEndpoint?cid=${corporationDetailId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueLightingBayStatus(corporationDetailId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Lighting/VenueLightingControllerBayStatuses?cid=${corporationDetailId}`;
    return this.get<any>(endpoint, retry);
  }
  
  // ! PUT Requests
  async sendBayLightStatus(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Lighting/SendBayLightStatus`;
    return this.put<any>(endpoint, body, retry);
  }

  // ! DELETE Requests
}
