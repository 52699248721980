import { Injectable, NgZone } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private hubConnection: HubConnection;
  private readonly hubUrl = `${environment.baseUrl}/notificationHub`;

  // Subjects for each event
  private reservationAddedSubject = new BehaviorSubject<any>(null);
  private reservationAssignedToBaySubject = new BehaviorSubject<any>(null);
  private reservationCheckedInSubject = new BehaviorSubject<any>(null);
  private reservationClosedSubject = new BehaviorSubject<any>(null);
  private reservationReopenedSubject = new BehaviorSubject<any>(null);
  private reservationCanceledSubject = new BehaviorSubject<any>(null);
  private pendingPaymentCompletedSubject = new BehaviorSubject<any>(null);
  private dailyFloorAddedSubject = new BehaviorSubject<any>(null);
  private dailyFloorUpdatedSubject = new BehaviorSubject<any>(null);
  private dailyBayAddedSubject = new BehaviorSubject<any>(null);
  private dailyBayUpdatedSubject = new BehaviorSubject<any>(null);
  private dailyBayBussingStatusChangedSubject = new BehaviorSubject<any>(null);

  // Subject to notify about connection status
  private connectionStatusSubject = new Subject<string>();
  public connectionStatus$ = this.connectionStatusSubject.asObservable();

  constructor(
    private authService: AuthService,
    private ngZone: NgZone
  ) {
    this.startConnection();
  }

  /**
   * Initialize the SignalR connection
   */
  private async startConnection(): Promise<void> {
    const token = this.authService.getAccessToken(); // Retrieve the JWT token from your AuthService
    const corporationDetailId = this.authService.getCorporationDetailId();

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubUrl, {
        accessTokenFactory: () => token
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect([0, 2000, 10000, 30000])
      .build();

    this.hubConnection.onreconnecting(error => {
      console.warn(`SignalR connection lost due to error "${error}". Reconnecting...`);
      this.connectionStatusSubject.next('Reconnecting');
    });

    this.hubConnection.onreconnected(connectionId => {
      console.log(`SignalR reconnected. Connected with connectionId "${connectionId}".`);
      this.connectionStatusSubject.next('Connected');
      // if (corporationDetailId) {
      //   this.hubConnection.invoke('JoinGroup', corporationDetailId)
      //     .catch(err => console.error('Error joining venue group:', err));
      // }
    });
  
    this.hubConnection.onclose(error => {
      console.error(`SignalR connection closed due to error: "${error}".`);
      this.connectionStatusSubject.next('Disconnected');
    });

    try {
      await this.hubConnection.start();
      console.log('SignalR Connected');
      this.connectionStatusSubject.next('Connected');
      // if (corporationDetailId) {
      //   await this.hubConnection.invoke('JoinGroup', corporationDetailId);
      // }
      this.registerOnServerEvents();
    } catch (err) {
      console.error('Error while starting SignalR connection: ', err);
      this.connectionStatusSubject.next('Disconnected');
    }
  }

  /**
   * Register server events with client-side handlers
   */
  private registerOnServerEvents(): void {
    if (!this.hubConnection) {
      console.error('Cannot register server events before connection is established.');
      return;
    }

    // Reservation Events
    this.hubConnection.on('ReservationAdded', (data) => {
      this.ngZone.run(() => {
        this.reservationAddedSubject.next(data);
      });
    });

    this.hubConnection.on('ReservationAssignedToBay', (data) => {
      this.ngZone.run(() => {
        this.reservationAssignedToBaySubject.next(data);
      });
    });

    this.hubConnection.on('ReservationCheckedIn', (data) => {
      this.ngZone.run(() => {
        this.reservationCheckedInSubject.next(data);
      });
    });

    this.hubConnection.on('ReservationClosed', (data) => {
      this.ngZone.run(() => {
        this.reservationClosedSubject.next(data);
      });
    });

    this.hubConnection.on('ReservationReopened', (data) => {
      this.ngZone.run(() => {
        this.reservationReopenedSubject.next(data);
      });
    });

    this.hubConnection.on('ReservationCanceled', (data) => {
      this.ngZone.run(() => {
        this.reservationCanceledSubject.next(data);
      });
    });

    this.hubConnection.on('PendingPaymentCompleted', (data) => {
      this.ngZone.run(() => {
        this.pendingPaymentCompletedSubject.next(data);
      });
    });

    // Daily Floor Events
    this.hubConnection.on('DailyFloorAdded', (data) => {
      this.ngZone.run(() => {
        this.dailyFloorAddedSubject.next(data);
      });
    });

    this.hubConnection.on('DailyFloorUpdated', (data) => {
      this.ngZone.run(() => {
        this.dailyFloorUpdatedSubject.next(data);
      });
    });

    // Daily Bay Events
    this.hubConnection.on('DailyBayAdded', (data) => {
      this.ngZone.run(() => {
        this.dailyBayAddedSubject.next(data);
      });
    });

    this.hubConnection.on('DailyBayUpdated', (data) => {
      this.ngZone.run(() => {
        this.dailyBayUpdatedSubject.next(data);
      });
    });

    this.hubConnection.on('DailyBayBussingStatusChanged', (data) => {
      this.ngZone.run(() => {
        this.dailyBayBussingStatusChangedSubject.next(data);
      });
    });
  }

  // ============================
  // Public Observables for Events
  // ============================

  // Reservation Events
  public onReservationAdded(): Observable<any> {
    return this.reservationAddedSubject.asObservable();
  }

  public onReservationAssignedToBay(): Observable<any> {
    return this.reservationAssignedToBaySubject.asObservable();
  }

  public onReservationCheckedIn(): Observable<any> {
    return this.reservationCheckedInSubject.asObservable();
  }

  public onReservationClosed(): Observable<any> {
    return this.reservationClosedSubject.asObservable();
  }

  public onReservationReopened(): Observable<any> {
    return this.reservationReopenedSubject.asObservable();
  }

  public onReservationCanceled(): Observable<any> {
    return this.reservationCanceledSubject.asObservable();
  }

  public onPendingPaymentCompleted(): Observable<any> {
    return this.pendingPaymentCompletedSubject.asObservable();
  }

  // Daily Floor Events
  public onDailyFloorAdded(): Observable<any> {
    return this.dailyFloorAddedSubject.asObservable();
  }

  public onDailyFloorUpdated(): Observable<any> {
    return this.dailyFloorUpdatedSubject.asObservable();
  }

  // Daily Bay Events
  public onDailyBayAdded(): Observable<any> {
    return this.dailyBayAddedSubject.asObservable();
  }

  public onDailyBayUpdated(): Observable<any> {
    return this.dailyBayUpdatedSubject.asObservable();
  }

  public onDailyBayBussingStatusChanged(): Observable<any> {
    return this.dailyBayBussingStatusChangedSubject.asObservable();
  }


  /**
   * Adds the current connection to a specified group.
   * @param groupName The name of the group to join.
   */
  public joinGroup(groupName: string): void {
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.invoke('JoinGroup', groupName)
        .then(() => console.log(`Joined group: ${groupName}`))
        .catch(err => console.error(`Error joining group ${groupName}:`, err));
    } else {
      console.warn('Cannot join group. Hub connection is not established.');
    }
  }

  /**
   * Removes the current connection from a specified group.
   * @param groupName The name of the group to leave.
   */
  public leaveGroup(groupName: string): void {
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.invoke('LeaveGroup', groupName)
        .then(() => console.log(`Left group: ${groupName}`))
        .catch(err => console.error(`Error leaving group ${groupName}:`, err));
    } else {
      console.warn('Cannot leave group. Hub connection is not established.');
    }
  }


  /**
   * This should be called when the application is about to unload, not by individual components
   */
  public stopConnection(): void {
    if (this.hubConnection && this.hubConnection.state !== HubConnectionState.Disconnected) {
      this.hubConnection.stop()
        .then(() => {
          console.log('SignalR Disconnected');
          this.connectionStatusSubject.next('Disconnected');
        })
        .catch(err => {
          console.error('Error while stopping SignalR connection: ', err);
        });
    }
  }
}
