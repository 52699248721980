import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class BayService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async getAvailableReservationHours(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Bay/GetAvailableReservationHours`;
    return this.post<any>(endpoint, body, retry);
  }

  async getBayReservationsForCalendar(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Bay/GetBayReservationsForCalendar`;
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  async getBayNamesByReservationId(rid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Bay/Names?rid=${encodeURIComponent(rid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getAvailableReservationDurations(cuid: string, isEvent = false, retry = true): Promise<any | null> {
    const endpoint = `/api/Bay/AvailableReservationDurations?cid=${encodeURIComponent(cuid)}&isEvent=${isEvent}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
}
