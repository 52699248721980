//Angular variables
import { Injectable } from '@angular/core';

//Internal service
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';
import { AccountService } from '../account/account.service';

@Injectable({
  providedIn: 'root'
})
export class CrmService extends BaseHttpService {

  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async getCustomerList(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/CustomerList`;
    return this.post<any>(endpoint, body, retry);
  }

  async downloadCustomerList(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/DownloadCustomerList`;
    return this.post<any>(endpoint, body, retry);
  }

  async crmBanGuest(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/BanGuest`;
    return this.post<any>(endpoint, body, retry);
  }
  
  // ! GET Requests
  async getCustomerDetails(profileVenueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/CustomerDetails?pvid=${profileVenueId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueBannedGuestsByVenueProfileId(cuid: string, gpid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/GetVenueBannedGuestsByVenueProfileId?cuid=${cuid}&gpid=${gpid}`;
    return this.get<any>(endpoint, retry);
  }

  async getCustomerHistoricNotes(profileVenueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/CustomerHistoricNotes?profileVenueId=${profileVenueId}`;
    return this.get<any>(endpoint, retry);
  }

  async getGuestTags(guestProfileId: string, corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/GetGuestTags?gpid=${guestProfileId}&cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  async getCustomerSocialAccounts(profileVenueId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/GetCustomerSocialAccounts?profileVenueId=${profileVenueId}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async updateCustomerVipStatus(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/UpdateCustomerVipStatus`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateCustomerPhoneNumbers(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/UpdateCustomerPhoneNumbers`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateCustomerEmail(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/UpdateCustomerEmail`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateCustomerGender(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/UpdateCustomerGender`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateCustomerShippingAddress(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/UpdateCustomerShippingAddress`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateCustomerBirthDate(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/UpdateCustomerBirthDate`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateCustomerWebsite(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/UpdateCustomerWebsite`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateCustomerJobInfo(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/UpdateCustomerJobInfo`;
    return this.put<any>(endpoint, body, retry);
  }

  async removeBanFromGuest(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/RemoveBanFromGuest`;
    return this.put<any>(endpoint, body, retry);
  }

  async removeGuestTag(guestTagId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/RemoveGuestTag?gtid=${guestTagId}`;
    return this.put<any>(endpoint, null, retry);
  }

  async addCustomerSocialAccount(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/CRM/AddCustomerSocialMediaAccountURL`;
    return this.put<any>(endpoint, body, retry);
  }

  // ! DELETE Requests

  
}
