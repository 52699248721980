//Angular variables
import { Injectable } from '@angular/core';

// Internal services
import { BaseHttpService } from '../../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class TrackmanService extends BaseHttpService {

  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  
  // ! GET Requests
  async getBookingLocationBays(corporationDetailId: string, trackmanLocationId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Trackman/BookingLocationBays?cid=${corporationDetailId}&lid=${trackmanLocationId}`;
    return this.get<any>(endpoint, retry);
  }

  async getMappableBookingLocationBays(corporationDetailId: string, trackmanLocationId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Trackman/MappableBookingLocationBays?cid=${corporationDetailId}&lid=${trackmanLocationId}`;
    return this.get<any>(endpoint, retry);
  }
  
  // ! PUT Requests

  // ! DELETE Requests
}
