import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AssignBayService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async getAvailableBaysForAssignment(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/AssignBay/GetAvailableBaysForAssignment`;
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  

  // ! PUT Requests
  async assignReservationToBay(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/AssignBay/AssignToBay`;
    return this.put<any>(endpoint, body, retry);
  }

}
