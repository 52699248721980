// Angular imports
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Internal services
import { VenueEmployeesResponse } from '../models/venue.model';

//Internal models



@Injectable()
export class AdminProvider {
    //venue employees variables
    showUpsertVenueEmployee: boolean = false;
    updatingEmployeeObject: VenueEmployeesResponse = null;
    showUpsertVenueEmployeeObservable = new Subject<boolean>();

    upsertEmployeeStep: number = 1;
    upsertEmployeeStepObservable = new Subject<number>();

    constructor() { }

    async showUpsertVenueEmployeeSlideOut(show: boolean, employee: VenueEmployeesResponse) {
        this.showUpsertVenueEmployee = show;
        this.updatingEmployeeObject = employee;
        this.showUpsertVenueEmployeeObservable.next(show);
    }
    async getShowUpsertVenueEmployee() {
        return this.showUpsertVenueEmployee;
    }
    async getUpdatingEmployeeObject() {
        return this.updatingEmployeeObject;
    }


    async updateUpsertEmployeeStep(step: number) {
        this.upsertEmployeeStep = step;
        this.upsertEmployeeStepObservable.next(step);
    }
    async getUpsertEmployeeStep() {
        return this.upsertEmployeeStep;
    }

}
