// Angular imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal services
import { AccountService } from '../services/account/account.service';


@Injectable()
export class ManageUsersProvider {
    //add user variables
    showAddUser: boolean = false;
    showAddUserObservable = new Subject<boolean>();

    constructor(
        public modalCtrl: ModalController,
        private appProvider: GlobalAppProvider,
        private accountService: AccountService
    ) {

    }

    async showAddUserSlideOut(show: boolean) {
        this.showAddUser = show;
        this.showAddUserObservable.next(show);
    }
    async getShowAddUser() {
        return this.showAddUser;
    }

}
