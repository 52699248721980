import { NgModule, isDevMode, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Other external imports
import * as Sentry from "@sentry/capacitor";
import * as SentryAngular  from "@sentry/angular";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalAppProvider } from './app.provider';

//internal services
import { AccountService } from './services/account/account.service';
import { VenueService } from './services/venue/venue.service';
import { CrmService } from './services/crm/crm.service';
import { ExportService } from './services/export/export.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { BaseHttpService } from './services/base-http-service.service';
import { GuestService } from './services/guest-service/guest-service.service';
import { ReservationService } from './services/reservations/reservation.service';
import { BayService } from './services/bay-service/bay-service.service';
import { PaymentService } from './services/payment-service/payment.service';
import { ReservationCheckInService } from './services/reservation-checkin-service/reservation-checkin.service';
import { AssignBayService } from './services/assign-bay-service/assign-bay.service';
import { BayStatusService } from './services/bay-status-service/bay-status.service';
import { AuthService } from './services/auth-service/auth.service';
import { AuditLogService } from './services/audit-log-service/audit-log.service';
import { SignalrService } from './services/signalr-service/signalr.service';
import { TrackmanService } from './services/gaming-providers/trackman-service/trackman.service';
import { InrangeService } from './services/gaming-providers/inrange-service/inrange.service';
import { AdminService } from './services/admin/admin.service';
import { LightingControllerService } from './services/lighting-service/lighting-controller.service';
import { MembershipService } from './services/membership-service/membership.service';
import { WaitlistService } from './services/waitlist-service/waitlist.service';

//internal providers
import { AccountProvider } from './providers/account.provider';
import { ManageUsersProvider } from './providers/manage-users.provider';
import { ReservationsProvider } from './providers/reservations.provider';
import { VenueSettingsProvider } from './providers/settings.provider';
import { CrmProvider } from './providers/crm.provider';
import { DashboardProvider } from './providers/dashboard.provider';
import { VenueProvider } from './providers/venue.provider';
import { AdminProvider } from './providers/admin.provider';
import { WaitlistProvider } from './providers/waitlist.provider';

//internal interceptors
import { AuthInterceptorService } from './interceptors/auth.interceptor';

import { ServiceWorkerModule } from '@angular/service-worker';

//environment
import { environment as ENV } from '../environments/environment';

// Init by passing the sibling SDK's init as the second parameter.
Sentry.init({
  dsn: ENV.sentryDsn,
  tracesSampleRate: ENV.sentryTracesSampleRate,
  release: ENV.appVersion,
  dist: ENV.appDistVersion,
  environment: ENV.sentryName,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracePropagationTargets: [
    'localhost',
    'https://23.119.176.134:2289',
    'https://23.119.176.134:2286',
    'https://23.119.176.134:2287',
    'https://web-eagleeye-dev-fe.azurewebsites.net',
    'https://web-eagleeye-stage-fe.azurewebsites.net',
    'https://web-eagleeye-prod-fe.azurewebsites.net',
    'https://web-eagleeye-proxy-dev-be.azurewebsites.net',
    'https://web-eagleeye-proxy-stage-be.azurewebsites.net',
    'https://web-eagleeye-proxy-prod-be.azurewebsites.net',
    'https://eagleeyeapp.ai',
    'https://spotlite.app'
  ],
  replaysSessionSampleRate: ENV.sentryReplaysSessionSampleRate,
  replaysOnErrorSampleRate: ENV.sentryReplaysOnErrorSampleRate,
}, SentryAngular.init);

@NgModule({
    declarations: [AppComponent],
    imports: [
      BrowserModule,
      HttpClientModule,
      IonicModule.forRoot(),
      AppRoutingModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      })],
  providers: [
    //providers
    AccountProvider,
    GlobalAppProvider,
    ManageUsersProvider,
    ReservationsProvider,
    VenueSettingsProvider,
    CrmProvider,
    DashboardProvider,
    VenueProvider,
    AdminProvider,
    WaitlistProvider,

    //services
    AccountService,
    VenueService,
    CrmService,
    ExportService,
    DashboardService,
    BaseHttpService,
    GuestService,
    ReservationService,
    BayService,
    PaymentService,
    ReservationCheckInService,
    AssignBayService,
    BayStatusService,
    AuthService,
    AuditLogService,
    SignalrService,
    TrackmanService,
    InrangeService,
    AdminService,
    LightingControllerService,
    MembershipService,
    WaitlistService,

    //interceptors
    AuthInterceptorService,

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler(),
    },
    {
        provide: SentryAngular.TraceService,
        deps: [Router],
    },
    {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [SentryAngular.TraceService],
        multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
