//Angular variables
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Capacitor variables
import { CapacitorHttp, HttpResponse, HttpOptions } from '@capacitor/core';

//Internal service
import { AccountService } from '../account/account.service';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

// Internal models
import { CurrentVenueISOTimeObject } from 'src/app/models/venues/objects/current-venue-iso-time.object';

//Internal variables
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VenueService extends BaseHttpService {

  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async addVenueDefaultFloor(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/NewVenueDefaultFloor`;
    return this.post<any>(endpoint, body, retry);
  }

  async addNewVenueDefaultFloorBay(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/AddNewVenueDefaultFloorBay`;
    return this.post<any>(endpoint, body, retry);
  }

  async duplicateVenueDefaultFloor(fpid: string, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/DuplicateVenueDefaultFloor?fpid=${fpid}`;
    return this.post<any>(endpoint, body, retry);
  }

  async duplicateVenueDefaultBay(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/DuplicateVenueDefaultBay`;
    return this.post<any>(endpoint, body, retry);
  }

  async getVenueDailyFloors(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/GetVenueDailyFloors`;
    return this.post<any>(endpoint, body, retry);
  }

  async addVenueEmployee(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/AddVenueEmployee`;
    return this.post<any>(endpoint, body, retry);
  }

  async getVenueDailyFloorsByDate(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/GetVenueDailyFloorsByDate`;
    return this.post<any>(endpoint, body, retry);
  }
  

  // ! GET Requests
  async getVenueCurrentISOTime(corporationUserId: string, retry = true): Promise<CurrentVenueISOTimeObject | null> {
    const endpoint = `/api/Venue/GetVenueCurrentISOTime?cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueAssignBayPermissions(corporationUserId, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/GetVenueAssignBayPermissions?cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueHoursByDate(selectedDateISO: string, corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/GetVenueHoursByDate?dateISO=${selectedDateISO}&cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueEmployees(corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/GetVenueEmployees?cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueCurrentTime(corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/GetVenueCurrentTime?cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueDefaultFloors(corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/VenueDefaultFloors?cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueDefaultFloorBays(dfid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/VenueDefaultFloorBays?dfid=${dfid}`;
    return this.get<any>(endpoint, retry);
  }

  async getVenueHourlyRates(cuid: string, dow: number, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/GetVenueHourlyRates?cuid=${cuid}&dow=${dow}`;
    return this.get<any>(endpoint, retry);
  }

  async getCorporationDetail(corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/GetCorporationDetail?cuid=${corporationUserId}`;
    return this.get<any>(endpoint, retry);
  }
  

  // ! PUT Requests
  async incrementVenueDefaultFloorIndex(fpid: string, cuid: string, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/IncrementVenueDefaultFloorIndex?fpid=${fpid}&cuid=${cuid}`;
    return this.put<any>(endpoint, body, retry);
  }

  async decrementVenueDefaultFloorIndex(fpid: string, cuid: string, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/DecrementVenueDefaultFloorIndex?fpid=${fpid}&cuid=${cuid}`;
    return this.put<any>(endpoint, body, retry);
  }

  async removeVenueDefaultFloor(fpid: string, rdf: boolean, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/RemoveVenueDefaultFloor?fpid=${fpid}&rdf=${rdf}`;
    return this.put<any>(endpoint, body, retry);
  }

  async removeVenueDefaultBay(bid: string, rdb: boolean, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/RemoveVenueDefaultBay?bid=${bid}&rdb=${rdb}`;
    return this.put<any>(endpoint, body, retry);
  }

  async incrementVenueDefaultBayIndex(bid: string, cuid: string, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/IncrementVenueDefaultBayIndex?bid=${bid}&cuid=${cuid}`;
    return this.put<any>(endpoint, body, retry);
  }

  async decrementVenueDefaultBayIndex(bid: string, cuid: string, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/DecrementVenueDefaultBayIndex?bid=${bid}&cuid=${cuid}`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateVenueHourlyRates(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/UpdateVenueHourlyRates`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateVenueDefaultFloorBay(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Venue/UpdateVenueDefaultFloorBay';
    return this.put<any>(endpoint, body, retry);
  }

  async updateVenueDefaultFloor(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Venue/UpdateVenueDefaultFloor';
    return this.put<any>(endpoint, body, retry);
  }

  async dismissBayBussingMode(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Venue/DismissBayBussingMode';
    return this.put<any>(endpoint, body, retry);
  }

  async updateCorporationDetail(body: object, retry = true): Promise<any | null> {
    const endpoint = '/api/Venue/UpdateCorporationDetail';
    return this.put<any>(endpoint, body, retry);
  }

  async updateVenueEmployee(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/UpdateVenueEmployee`;
    return this.put<any>(endpoint, body, retry);
  }

  // ! DELETE Request
  async removeVenueEmployee(venueEmployeeId: string, body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Venue/RemoveVenueEmployee?venueEmployeeId=${venueEmployeeId}`;
    return this.delete<any>(endpoint, body, retry);
  }
}
