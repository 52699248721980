// Angular imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal services
import { AccountService } from '../services/account/account.service';

// Internal models
import { AddWaitlistReservationStepObject } from '../models/waitlist/objects/add-waitlist-reservation-step.object';
import { WaitlistTimeObject } from '../models/waitlist/objects/waitlist-time.object';


@Injectable()
export class WaitlistProvider {
    // add waitlist reservation variables
    steps: AddWaitlistReservationStepObject[] = [
        { index: 0, name: 'Guest Count', percentage: 10 },
        { index: 1, name: 'Seat or Waitlist', percentage: 38 },
        { index: 2, name: 'Guest Details', percentage: 63},
        { index: 3, name: 'Submitted', percentage: 95 }
    ];
    currentStep: number = 0;
    currentStepObservable = new Subject<number>();
    currentPercentage: number = 10;
    currentPercentageObservable = new Subject<number>();

    // guest count variables
    selectedGuestCount: number;
    selectedGuestCountObservable = new Subject<number>();

    // selected reservation period variables
    selectedReservationPeriod: WaitlistTimeObject;
    selectedReservationPeriodObservable = new Subject<WaitlistTimeObject>();

    // processing add waitlist reservation variables
    isProcessingAddWaitlistReservation: boolean = false;
    isProcessingAddWaitlistReservationObservable = new Subject<boolean>();

    constructor(
        public modalCtrl: ModalController,
        private appProvider: GlobalAppProvider,
        private accountService: AccountService
    ) {

    }

    // stepper methods
    async getSteps(): Promise<AddWaitlistReservationStepObject[]> {
        return this.steps;
    }

    async getCurrentStep(): Promise<number> {
        return this.currentStep;
    }

    async getCurrentPercentage(): Promise<number> {
        return this.currentPercentage;
    }

    async setCurrentStep(step: number) {
        this.currentStep = step;
        const newPercentage = this.steps[step].percentage;
        this.setCurrentPercentage(newPercentage);
        this.currentStepObservable.next(step);
    }

    async setCurrentPercentage(percentage: number) {
        this.currentPercentage = percentage;
        this.currentPercentageObservable.next(percentage);
    }


    // guest count methods
    async setSelectedGuestCount(guestCount: number) {
        this.selectedGuestCount = guestCount;
        this.selectedGuestCountObservable.next(guestCount);
    }

    async getSelectedGuestCount(): Promise<number> {
        return this.selectedGuestCount;
    }


    // selected reservation period methods
    async setSelectedReservationPeriod(period: WaitlistTimeObject) {
        this.selectedReservationPeriod = period;
        this.selectedReservationPeriodObservable.next(period);
    }

    async getSelectedReservationPeriod(): Promise<WaitlistTimeObject> {
        return this.selectedReservationPeriod;
    }

    // processing add waitlist reservation methods
    async processAddWaitlistReservation() {
        this.isProcessingAddWaitlistReservation = true;
        this.isProcessingAddWaitlistReservationObservable.next(true);
        this.setCurrentStep(3);
    }
    async revertAddWaitlistReservation() {
        this.isProcessingAddWaitlistReservation = false;
        this.isProcessingAddWaitlistReservationObservable.next(false);
        this.setCurrentStep(2);
    }

    // reset methods
    async resetWaitlistProvider() {
        this.currentStep = 0;
        this.currentPercentage = 10;
        this.selectedGuestCount = 0;
        this.selectedReservationPeriod = null;
    }

}
