// Angular imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal services
import { AccountService } from '../services/account/account.service';

//Internal models
import { VenueEmployeesResponse } from '../models/venue.model';
import { CorporationMembershipsDto } from '../models/memberships/objects/corporation-memberships.dto';

@Injectable()
export class VenueSettingsProvider {
    //modify hourly rates variables
    showHourlyRates: boolean = false;
    showHourlyRatesObservable = new Subject<boolean>();

    //venue employees variables
    showVenueEmployees: boolean = false;
    showVenueEmployeesObservable = new Subject<boolean>();
    showUpsertVenueEmployee: boolean = false;
    updatingEmployeeObject: VenueEmployeesResponse = null;
    showUpsertVenueEmployeeObservable = new Subject<boolean>();

    upsertEmployeeStep: number = 1;
    upsertEmployeeStepObservable = new Subject<number>();

    //venue info variables
    showVenueInfo: boolean = false;
    showVenueInfoObservable = new Subject<boolean>();

    // venue memberships variables
    showVenueMemberships: boolean = false;
    showVenueMembershipsObservable = new Subject<boolean>();

    showUpsertVenueMemberships: boolean = false;
    upsertVenueMembershipObject: CorporationMembershipsDto | null = null;
    showUpsertVenueMembershipsObservable = new Subject<boolean>();

    constructor(
        public modalCtrl: ModalController,
        private appProvider: GlobalAppProvider,
        private accountService: AccountService
    ) {

    }

    async showHourlyRatesSlideOut(show: boolean) {
        this.showHourlyRates = show;
        this.showHourlyRatesObservable.next(show);
    }
    async getShowHourlyRates() {
        return this.showHourlyRates;
    }


    async showVenueEmployeesSlideOut(show: boolean) {
        this.showVenueEmployees = show;
        this.showVenueEmployeesObservable.next(show);
    }
    async getShowVenueEmployees() {
        return this.showVenueEmployees;
    }
    async showUpsertVenueEmployeeSlideOut(show: boolean, employee: VenueEmployeesResponse) {
        this.showUpsertVenueEmployee = show;
        this.updatingEmployeeObject = employee;
        this.showUpsertVenueEmployeeObservable.next(show);
    }
    async getShowUpsertVenueEmployee() {
        return this.showUpsertVenueEmployee;
    }
    async getUpdatingEmployeeObject() {
        return this.updatingEmployeeObject;
    }


    async updateUpsertEmployeeStep(step: number) {
        this.upsertEmployeeStep = step;
        this.upsertEmployeeStepObservable.next(step);
    }
    async getUpsertEmployeeStep() {
        return this.upsertEmployeeStep;
    }


    async showVenueInfoSlideOut(show: boolean) {
        this.showVenueInfo = show;
        this.showVenueInfoObservable.next(show);
    }
    async getShowVenueInfo() {
        return this.showVenueInfo;
    }


    // venue memberships
    async showVenueMembershipsSlideOut(show: boolean) {
        this.showVenueMemberships = show;
        this.showVenueMembershipsObservable.next(show);
    }

    async getShowVenueMemberships() {
        return this.showVenueMemberships;
    }

    async showUpsertVenueMembershipsSlideOut(show: boolean, membership: CorporationMembershipsDto | null) {
        this.showUpsertVenueMemberships = show;
        this.upsertVenueMembershipObject = membership;
        this.showUpsertVenueMembershipsObservable.next(show);
    }

    async getShowUpsertVenueMemberships() {
        return this.showUpsertVenueMemberships;
    }

    async getUpsertVenueMembershipObject() {
        return this.upsertVenueMembershipObject;
    }

}
