import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class WaitlistService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  

  // ! GET Requests
  async getWaitlistTimes(corporationDetailId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Waitlist/WaitlistTimes?cid=${encodeURIComponent(corporationDetailId)}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async moveWaitlistReservationToReservations(reservationId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Waitlist/MoveToReservations?rid=${encodeURIComponent(reservationId)}`;
    return this.put<any>(endpoint, null, retry);
  }
}
