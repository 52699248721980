// src/app/services/auth-interceptor.service.ts
import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { AuthService } from '../services/auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {
  private isRefreshing = false;
  private refreshTokenPromise: Promise<string | null> | null = null;

  constructor(private authService: AuthService) { }

  /**
   * Makes an authenticated HTTP request by attaching the Access Token.
   * If a 401 Unauthorized error occurs, attempts to refresh the token and retry the request.
   */
  async makeAuthenticatedRequest(options: any): Promise<HttpResponse> {
    const accessToken = this.authService.getAccessToken();

    if (accessToken) {
      options.headers = options.headers || {};
      options.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    try {
      const response: HttpResponse = await CapacitorHttp.request(options);

      if (response.status === 401 && this.authService.getRefreshToken()) {
        // If already refreshing, wait for the existing refresh to complete
        if (this.isRefreshing && this.refreshTokenPromise) {
          const newAccessToken = await this.refreshTokenPromise;
          if (newAccessToken) {
            options.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return CapacitorHttp.request(options);
          } else {
            throw new Error('Unable to refresh access token');
          }
        }

        // Start refreshing
        this.isRefreshing = true;
        this.refreshTokenPromise = this.authService.refreshToken()
          .then(refreshResponse => {
            this.isRefreshing = false;
            return refreshResponse.AccessToken;
          })
          .catch(async (refreshError) => {
            this.isRefreshing = false;
            await this.authService.logout();
            throw refreshError;
          });

        const newAccessToken = await this.refreshTokenPromise;
        if (newAccessToken) {
          options.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return CapacitorHttp.request(options);
        } else {
          throw new Error('Unable to refresh access token');
        }
      }

      return response;
    } catch (error) {
      throw error;
    }
  }
}
