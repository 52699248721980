import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class ReservationService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async addWaitlistReservation(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/WaitlistReservation`;
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  async getReservationByBayId(bid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/BayId?bid=${encodeURIComponent(bid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getClosedReservationDetails(rid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ClosedReservationDetails?rid=${encodeURIComponent(rid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getReservationAddedBy(rid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ReservationAddedBy?rid=${encodeURIComponent(rid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getReservationsByDate(selectedDay: string, retrieveAssigned: boolean, corporationUserId: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ReservationsByDate?selectedDay=${encodeURIComponent(selectedDay)}&assigned=${encodeURIComponent(retrieveAssigned)}&cuid=${encodeURIComponent(corporationUserId)}`;
    return this.get<any>(endpoint, retry);
  }

  async getReservationDetailById(rid: string, cuid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ReservationDetailById?rid=${encodeURIComponent(rid)}&cuid=${encodeURIComponent(cuid)}`;
    return this.get<any>(endpoint, retry);
  }

  async resendReservationEmail(rid: string, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ResendReservationEmail?rid=${encodeURIComponent(rid)}`;
    return this.get<any>(endpoint, retry);
  }

  async getTotalReservationGuestsAllowed(cid: string, isEvent: boolean = false, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/TotalReservationGuestsAllowed?cid=${encodeURIComponent(cid)}&isEvent=${encodeURIComponent(isEvent)}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async updateReservationGender(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ReservationGender`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateReservationVipStatus(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ReservationVipStatus`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateItemizedReservationGenders(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ItemizedReservationGenders`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateReservationName(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ReservationName`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateReservationContact(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ReservationContact`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateReservationCheckedIn(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/ReservationCheckedIn`;
    return this.put<any>(endpoint, body, retry);
  }

  async closeReservation(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/CloseReservation`;
    return this.put<any>(endpoint, body, retry);
  }

  async reopenReservation(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/Reopen`;
    return this.put<any>(endpoint, body, retry);
  }

  async cancelReservation(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/CancelReservation`;
    return this.put<any>(endpoint, body, retry);
  }

  async noShowReservation(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/NoShowReservation`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateReservationStatus(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/Reservation/Status`;
    return this.put<any>(endpoint, body, retry);
  }
}
