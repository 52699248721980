// Angular imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal services
import { AccountService } from '../services/account/account.service';

//Internal models
import { CustomerDetailsObject, BannedGuestDetailsObject } from '../models/crm.model';


@Injectable()
export class CrmProvider {
    //selected customer details modal variables
    selectedCustomerDetails: CustomerDetailsObject;
    selectedCustomerDetailsObservable = new Subject();

    //show banned customer details modal variables
    bannedCustomerDetails: BannedGuestDetailsObject;
    showBannedCustomerDetailsObservable = new Subject();
    showBannedCustomerDetails: boolean;

    //show add individual customer modal variables
    showAddIndividualCustomerObservable = new Subject();
    showAddIndividualCustomer: boolean;

    constructor(
        public modalCtrl: ModalController,
        private appProvider: GlobalAppProvider,
        private accountService: AccountService
    ) {

    }

    // eslint-disable-next-line
    /**
     * @param  {CustomerDetailsObject} customerDetails
     * This sets the selected customer details variable and triggers the respective Subject so that all observables fire off
     */
    async setSelectedCustomerDetails(customerDetails: CustomerDetailsObject) {
        this.selectedCustomerDetailsObservable.next(customerDetails);
        this.selectedCustomerDetails = customerDetails;
    }
    async getSelectedCustomerDetails() {
        return this.selectedCustomerDetails;
    }


    // eslint-disable-next-line
    /**
     * @param  {BannedGuestObject} bannedCustomerDetails
     * This sets the banned customer details variable and triggers the respective Subject so that all observables fire off
     */
    async setBannedCustomerDetails(show: boolean, bannedCustomerDetails: BannedGuestDetailsObject) {
        this.showBannedCustomerDetailsObservable.next(show);
        this.showBannedCustomerDetails = show;
        this.bannedCustomerDetails = bannedCustomerDetails;
    }
    async getShowBannedCustomerDetails() {
        return this.showBannedCustomerDetails;
    }
    async getBannedCustomerDetails() {
        return this.bannedCustomerDetails;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} show
     * This sets the show add individual customer variable and triggers the respective Subject so that all observables fire off
     */
    async setShowAddIndividualCustomer(show: boolean) {
        this.showAddIndividualCustomerObservable.next(show);
        this.showAddIndividualCustomer = show;
    }
    async getShowAddIndividualCustomer() {
        return this.showAddIndividualCustomer;
    }

}
