import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth-service/auth.service';
import { Router } from '@angular/router';

export const authGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const accessToken = authService.getAccessToken();
  const refreshToken = authService.getRefreshToken();

  if (accessToken && !authService.isAccessTokenExpired()) {
    // Access Token is valid
    return true;
  } else if (refreshToken) {
    // Attempt to refresh Access Token
    try {
      const refreshResponse = await authService.refreshToken();
      if (refreshResponse.AccessToken && refreshResponse.RefreshToken) {
        return true;
      }
    } catch (error) {
      console.error('Refresh token failed:', error);
    }
  }

  // If we reach here, redirect to login
  router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  return false;
};
