/* eslint-disable max-len */
//Angular variables
import { Injectable } from '@angular/core';

//Capacitor variables
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

//Other external imports
import * as XLSX from 'xlsx';

//Internal services
import { AccountService } from '../account/account.service';

//Internal variables
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private accountService: AccountService
  ) { }

  async exportToExcel(filename: string,
    rows: object[] | object,
    sheetName: string = 'Sheet1',
    columnWidths: number[] = [],
    rowHeights: number[] = [],
    header: string[] = [],
    footer: string[] = []) {

      if (!rows) {
        return;
      }

      // If rows is a single object, wrap it in an array
      const data = Array.isArray(rows) ? rows : [rows];

      // Prepare workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Set column widths
      ws['!cols'] = columnWidths.map(w => ({wch: w}));

      // Set row heights (only works for the xlsx format, and not for older xls)
      ws['!rows'] = rowHeights.map(h => ({hpt: h}));

      // Add auto-filter to all columns, use the range from the sheet directly
      ws['!autofilter'] = { ref: ws['!ref'] };

      // Add header and footer
      ws['!margins'] = { header: 0.5, footer: 0.5 };
      ws['!header'] = header;
      ws['!footer'] = footer;

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      // Write workbook and force download
      XLSX.writeFile(wb, `${filename}.xlsx`);
  }
}
