import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class BayStatusService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests

  // ! GET Requests

  // ! PUT Requests
  async updateBayBussingAsync(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/BayStatus/Bussing`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateBayOutOfOrderAsync(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/BayStatus/OutOfOrder`;
    return this.put<any>(endpoint, body, retry);
  }

  async updateBayOfflineAsync(body: object, retry = true): Promise<any | null> {
    const endpoint = `/api/BayStatus/Offline`;
    return this.put<any>(endpoint, body, retry);
  }

}
