import { CurrentVenueISOTimeObject } from "../../venues/objects/current-venue-iso-time.object";

export class UserTokens {
    accessToken: string;
    refreshToken: string;
    userId: string;
    corporationDetailId: string | null;
    corporationUserId: string | null;
    currentVenueTime: CurrentVenueISOTimeObject | null;
    userIdentifier: number;
    name: string | null;
  
    constructor(json: string) {
        if (json == null) {return;}
  
        const obj = JSON.parse(json);
        
        this.accessToken = obj.accessToken;
        this.refreshToken = obj.refreshToken;
        this.userId = obj.userId;
        this.corporationDetailId = obj.corporationDetailId;
        this.corporationUserId = obj.corporationUserId;
        this.currentVenueTime = obj.currentVenueTime;
        this.userIdentifier = obj.userIdentifier;
        this.name = obj.name;
    }
}